/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "Yanis Miraoui",
  logo_name: "Yanis Miraoui",
  nickname: "Yanis",
  full_name: "Yanis Najy Miraoui",
  subTitle:
    "I am a MS in Data Science student at Stanford University. 🇺🇸 I have graduated from Imperial College London and ETH Zürich with a MSci degree in Mathematics. 🎓 I am mostly interested in Problem Solving and Data Science.⚡ In my free time, I love traveling 🌇, playing sports 🏃‍♂️ and watching Formula 1 🏎️.",
  resumeLink:
    "https://yanismiraoui.github.io/CV%20Yanis%20MIRAOUI.pdf",
  mail: "mailto:yanis.miraoui@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/yanismiraoui",
  linkedin: "https://www.linkedin.com/in/yanis-miraoui-54377a1b8/",
  gmail: "yanis.miraoui@gmail.com",
  outlook: "ymiraoui@stanford.edu",
};

const skills = {
  data: [
    {
      title: "Data Scientist",
      fileName: "FullStackImg",
      skills: [
        "⚡ Developing predictive models using advanced machine learning algorithms",
        "⚡ Working with Large Language Models (LLMs), Transformers and LLM Agents",
        "⚡ Experience with Natural Language Processing (NLP) and Computer Vision tasks",
        "⚡ Implementing deep learning models using PyTorch and TensorFlow",
        "⚡ Expertise in data visualization and statistical analysis",
        "⚡ Proficient in feature engineering and model optimization techniques",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
        {
          skillName: "R",
          fontAwesomeClassname: "simple-icons:r",
          style: {
            color: "#276DC3",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "Jupyter Notebook",
          fontAwesomeClassname: "simple-icons:jupyter",
          style: {
            color: "#F37626",
          },
        },
        {
          skillName: "Google Colab",
          fontAwesomeClassname: "simple-icons:googlecolab",
          style: {
            color: "#F9AB00",
          },
        },
        {
          skillName: "Hugging Face",
          fontAwesomeClassname: "simple-icons:huggingface",
          style: {
            color: "#FFD21E",
          },
        },
        {
          skillName: "Amazon Web Services",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#232F3E",
          },
        },
        {
          skillName: "Microsoft Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0078D4",
          },
        },
        {
          skillName: "Google Cloud Platform",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Digital Ocean",
          fontAwesomeClassname: "simple-icons:digitalocean",
          style: {
            color: "#0080FF",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#430098",
          },
        },
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#DE33A6",
          },
        },
        {
          skillName: "LaTeX",
          fontAwesomeClassname: "simple-icons:latex",
          style: {
            color: "#008080",
          },
        },
        {
          skillName: "Microsoft Office",
          fontAwesomeClassname: "simple-icons:microsoftoffice",
          style: {
            color: "#D83B01",
          },
        },
        {
          skillName: "G Suite",
          fontAwesomeClassname: "simple-icons:googledrive",
          style: {
            color: "#4285F4",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Stanford University",
      subtitle: "MS in Data Science | Statistics",
      logo_path: "stanford.png",
      alt_name: "Stanford",
      duration: "September 2023 - June 2025",
      descriptions: [
        "⚡ I am a MS in Data Science student at Stanford University.",
        "⚡ I am a Research Assistant at the Stanford Computational Neuroscience (CNS) Lab.",
        "⚡ I was a Course Assistant for the CS 229 Machine Learning course (Summer 2024).",
        "⚡ I was a Course Assistant for the MS&E 236 / CS 225 Machine Learning for Discrete Optimization course (Spring 2024).",
        "⚡ I was a Course Assistant for the MS&E 211DS Introduction to Optimization: Data Science course (Winter 2024).",
        "⚡ I was a Course Assistant for the CS 236 Deep Generative Models course (Fall 2023) - Outstanding Work Award, top 5% Course Assistant.",
        "⚡ I was a Research Assistant within the MS&E Department.",
      ],
      website_link: "https://statistics.stanford.edu/academic-programs/statistics-ms/statistics-data-science-ms-overview",
    },
    {
      title: "Imperial College London and ETH Zürich",
      subtitle: "MSci in Mathematics",
      logo_path: "icl.png",
      alt_name: "ICL",
      duration: "October 2019 - June 2023",
      descriptions: [
        "⚡ I have graduated from a MSci in Mathematics at Imperial College London and ETH Zürich.",
        "⚡ I have studied core subjects like Advanced Machine Learning, Probabilistics Artificial Intelligence, Natural Language Processing, Computational Statistics, Methods for Data Science, etc...",
        "⚡ During my studies, I have also completed various online courses on Coursera and edX.",
        "⚡ Moreover, I have implemented several projects based on what I've learnt during my curriculum.",
        "⚡ My Master Thesis research focused on the application of state-of-the-art deep unsupervised learning methods to identify and characterize TCR specificity 🧬.",
        "🌟 Grade: First Class Honors."
      ],
      website_link: "https://www.imperial.ac.uk/study/ug/courses/mathematics-department/mathematics-europe-msci/",
    },
    {
      title: "Lycée Victor Hugo, Marrakesh, Morocco",
      subtitle: "High School Diploma : Scientific, Mathematics",
      logo_path: "lvh.png",
      alt_name: "LVH",
      duration: "August 2017 - June 2019",
      descriptions: [
        "🌟 High Honors : 19.21/20."
      ],
      website_link: "https://www.citescolairehugorenoir.org/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Data Science in Python",
      subtitle: "Coursera",
      logo_path: "coursera.png",
      certificate_link:
        "",
      alt_name: "Data Science in Python",
      // color_code: "#2AAFED",
      color_code: "#2AAFED",
    },
    {
      title: "Python for Data Science and AI",
      subtitle: "Coursera",
      logo_path: "coursera.png",
      certificate_link:
        "",
      alt_name: "Python for Data Science and AI",
      // color_code: "#2AAFED",
      color_code: "#2AAFED",
    },
    {
      title: "Databases and SQL for Data Science",
      subtitle: "Coursera",
      logo_path: "coursera.png",
      certificate_link:
        "",
      alt_name: "Databases and SQL for Data Science",
      // color_code: "#2AAFED",
      color_code: "#2AAFED",
    },
    {
      title: "Mathematics for Machine Learning: Linear Algebra",
      subtitle: "Coursera",
      logo_path: "coursera.png",
      certificate_link:
        "",
      alt_name: "Mathematics for Machine Learning: Linear Algebra",
      // color_code: "#2AAFED",
      color_code: "#2AAFED",
    },
    {
      title: "Mathematics for Machine Learning: Multivariate Calculus",
      subtitle: "Coursera",
      logo_path: "coursera.png",
      certificate_link:
        "",
      alt_name: "Mathematics for Machine Learning: Multivariate Calculus",
      // color_code: "#2AAFED",
      color_code: "#2AAFED",
    },
    {
      title: "Machine Learning with Python",
      subtitle: "Coursera",
      logo_path: "coursera.png",
      certificate_link:
        "",
      alt_name: "Machine Learning with Python",
      // color_code: "#2AAFED",
      color_code: "#2AAFED",
    },
    {
      title: "SQL for Data Science",
      subtitle: "edX",
      logo_path: "EdX.png",
      certificate_link:
        "",
      alt_name: "SQL for Data Science",
      color_code: "#846DE6",
    },
    {
      title: "Data Science: Linear Regression",
      subtitle: "edX",
      logo_path: "EdX.png",
      certificate_link:
        "",
      alt_name: "Data Science: Linear Regression",
      color_code: "#846DE6",
    },
    {
      title: "Microsoft Office Specialist",
      subtitle: "PowerPoint Expert",
      logo_path: "powerpoint.png",
      certificate_link:
        "",
      alt_name: "Microsoft Office Specialist",
      color_code: "#E2405F",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with different companies and organizations to improve my skills and knowledge. I have also participated in multiple events such as hackathons and problem-solving competitions.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Research Data Scientist",
          company: "Oracle",
          company_url: "https://www.oracle.com/",
          logo_path: "oracle.png",
          duration: "September 2024 - Present",
          location: "Office",
          description:
          `Designed, implemented, and evaluated fully integrated LLM Agents at Oracle Labs, improving workflow automation for data scientists. Collaborated with cross-functional teams to identify optimization opportunities, leveraging LLMs to improve agent accuracy.

          `,
          color: "#C74634",
        },
        {
          title: "Applied Research Data Scientist (Intern)",
          company: "Snowflake",
          company_url: "https://www.snowflake.com/en/",
          logo_path: "snowflake.png",
          duration: "June 2024 - August 2024",
          location: "Office",
          description:
          `Developed a RAG chatbot enhancing sales team efficiency by integrating knowledge from PDFs and web pages. Built a multilingual translation tool leveraging LLMs to improve translation quality and reducing content translation time and cost. Led the creation of a forecasting model accurately predicting Sales Qualified Opportunities (SQOs) with less than 12% error rate.
          `,
          color: "#00A1D9",
        },
        {
          title: "Research Assistant at the Stanford Computational Neuroscience Laboratory (CNS Lab)",
          company: "Stanford University",
          company_url: "https://cnslab.stanford.edu/",
          logo_path: "stanford.png",
          duration: "September 2023 - August 2024",
          location: "Office",
          description:
          `Built state-of-the-art Graph Neural Network models to measure neural fMRI connectivity of different brain parcels. Developed an optimal parcellation method of brain regions reducing computational complexity and processing time for a wide range of downstream tasks by 70%. Published and presented my research findings on GNN models for neural connectivity at the MICCAI 2024 conference.

          `,
          color: "#8C1515",
        },
        {
          title: "Course Assistant for the CS 229 Machine Learning course",
          company: "Stanford University",
          company_url: "https://explorecourses.stanford.edu/instructor/ymiraoui",
          logo_path: "stanford.png",
          duration: "June 2024 - August 2024",
          location: "Office",
          description:
          `Lectured classes on advanced techniques for applying optimization in Machine Learning. Led engaging discussion sections, answered questions, and provided guidance on course content. Designed and graded assignments and exams.
          `,
          color: "#8C1515",
        },
        {
          title: "Course Assistant for the MS&E 236 / CS 225 Machine Learning for Discrete Optimization course",
          company: "Stanford University",
          company_url: "https://explorecourses.stanford.edu/instructor/ymiraoui",
          logo_path: "stanford.png",
          duration: "March 2024 - June 2024",
          location: "Office",
          description:
          `Designed and graded assignments and exams, as well as mentored students' final projects. Facilitated student learning by leading discussion sections, answering questions, and providing guidance on course content. Mentored and guided students in the development and execution of their course projects and served as a resource for students to explore advanced concepts, troubleshoot technical issues, and refine their project implementations.
          `,
          color: "#8C1515",
        },
        {
          title: "Course Assistant for the MS&E 211DS Introduction to Optimization: Data Science course",
          company: "Stanford University",
          company_url: "https://explorecourses.stanford.edu/instructor/ymiraoui",
          logo_path: "stanford.png",
          duration: "January 2024 - March 2024",
          location: "Office",
          description:
          `Helped students with their projects and assignments. Designed and graded the assignments and projects of the students. Led engaging weekly discussion sections, answered questions, and provided guidance on course content.
          `,
          color: "#8C1515",
        },
        {
          title: "Course Assistant for the CS 236 Deep Generative Models course",
          company: "Stanford University",
          company_url: "https://deepgenerativemodels.github.io/",
          logo_path: "stanford.png",
          duration: "September 2023 - January 2024",
          location: "Office",
          description:
          `Nominated for the 'Outstanding Work' award as a Course Assistant and chosen among the top 5% for my dedication and significant contributions. Helped students with their projects and assignments. Designed and graded the assignments and projects of the students.
          `,
          color: "#8C1515",
        },
        {
          title: "AI Researcher (Intern)",
          company: "Egoli Media",
          company_url: "https://www.egolimedia.com/",
          logo_path: "egoli.png",
          duration: "August 2021 - September 2021",
          location: "Remote",
          description:
          `Researched and built the company’s training dataset of athletes and sports. Improved the company’s AI system in identifying players, actions, brands and other interesting moments within video content of football, tennis and cricket.
          `,
          color: "#0071C5",
        },
        {
          title: "Machine Learning Engineer (Intern)",
          company: "Sopra Steria Group",
          company_url: "https://www.soprasteria.com/",
          logo_path: "soprasteria.jpg",
          duration: "June 2021 - August 2021",
          location: "Office",
          description:
          `Led an entire machine learning project from conception to implementation in a context of Telecom trouble ticketing resolution. Developed a full machine learning CI/CD pipeline with Google Cloud Platform integrating Continuous Learning. Researched how new AI models and tools (BERT, Vertex AI, …) could reduce the resolution time of support tickets. 
          `,
          color: "#ff7d45",
        },
      ],
    },
    {
      title: "Volunteerships and Leadership",
      experiences: [
        {
          title: "Winner",
          company: "CODE4GREEN Hackathon",
          company_url: "https://code4green.devpost.com/",
          logo_path: "code4green.png",
          duration: "2020",
          location: "",
          description:
          `Developed a machine learning application using Python that can detect with satellite imagery the pollution routes and cycles over rivers and seas to allow NGOs to better understand and target risk areas. Awarded €3,000 for developing a machine learning application detecting pollution routes in water bodies using satellite imagery.
          `,
          color: "#4d7002",
        },
        {
          title: "Completed “La Piscine” Coding Bootcamp",
          company: "Ecole 42",
          company_url: "https://42.fr/en/homepage/",
          logo_path: "42.jpg",
          duration: "August 2020 - September 2020",
          location: "On-site",
          description:
          `Improved my coding skills with C, Shell, and Git by achieving multiple projects, allowing me to finish in the top 15% of the class. Coordinated 5+ group coding projects with students from all over the world to build fundamental C# software.
          `,
          color: "#040f26",
        },
        {
          title: "Finished 6th",
          company: "French Mathematics Olympics",
          company_url: "https://www.education.gouv.fr/les-olympiades-nationales-de-mathematiques-5732",
          logo_path: "matholympics.png",
          duration: "2019",
          location: "",
          description:
          `Selected as part of 3 students out of 100+ to work on complex mathematical problems. Competed and finished 6th as part of the French Mathematics Olympics
          `,
          color: "#b81f00",
        },
        {
          title: "French Instructor and Translator ",
          company: "Universidad Veracruzana and Alliance Française",
          company_url: "https://www.uv.mx/prensa/general/estudiante-marroqui-realiza-labor-voluntaria-en-la-uv/",
          logo_path: "veracruz.png",
          duration: "June 2018 - September 2018",
          location: "On-site",
          description:
          ` Taught daily French courses to 150+ Mexican, international students and members of staff. Translated 10+ scientific research articles of the University of Veracruz from Spanish to French, to provide them with a broader reach.
          `,
          color: "#4285F4",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "I have always been passionate about working within a team to find concrete solutions across different intellectual fields, such as geopolitics, science, and computing. I am always looking for new complex problems to solve and new challenges to face applying technology innovation. Below are some of my projects. Most of the projects are already freely accessible on GitHub.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "yanis2.png",
    description:
      "You can contact me by email or by message via LinkedIn with the links mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Github Page",
    subtitle:
      "I publish most of my projects on Github, please find below the link to my Github page.",
    link: "https://github.com/yanismiraoui",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "GitShine 🌟",
      url: "https://www.gitshine.com/",
      description: "GitShine is an app that allows you to easily create and manage automated commit scheduling, customizable commit content, and a unique community star exchange feature, enhancing user profiles and fostering a developer community.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "NodeJS",
          iconifyClass: "logos:nodejs-icon",
        },
        {
          name: "TypeScript",
          iconifyClass: "simple-icons:typescript",
        },
        {
          name: "Git",
          iconifyClass: "logos:git-icon",
        },
        {
          name: "Vercel",
          iconifyClass: "simple-icons:vercel",
        },
        {
          name: "React",
          iconifyClass: "simple-icons:react",
        },
        {
          name: "PlanetScale",
          iconifyClass: "simple-icons:planetscale",
        },
      ],
    },
    {
      id: "1",
      name: "GNNs for Neuroimaging 🧠",
      url: "https://github.com/yanismiraoui/",
      description: "The aim of this research project is to leverage Graph Neural Networks and the associated techniques to Neuroimaging. This study is carried out within the Stanford CNS Lab.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Git",
          iconifyClass: "logos:git-icon",
        },
        {
          name: "Google Colab",
          iconifyClass: "simple-icons:googlecolab",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
        {
          name: "Replit",
          iconifyClass: "simple-icons:replit",
        },
      ],
    },
    {
      id: "2",
      name: "Deep learning for TCR specificity 🧬",
      url: "https://github.com/yanismiraoui/M4R-Project-Notebooks",
      description: "My investigation focused on the analysis of different unsupervised deep learning techniques in order to accurately cluster T-cell receptors with similar specificity. My research focused on developing and applying state-of-the- art modelling techniques, including AutoEncoders, Variational AutoEncoders and Transformers, to analyze TCR data.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Git",
          iconifyClass: "logos:git-icon",
        },
        {
          name: "Google Colab",
          iconifyClass: "simple-icons:googlecolab",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
        {
          name: "Heroku",
          iconifyClass: "logos-heroku-icon",
        },
        {
          name: "Replit",
          iconifyClass: "simple-icons:replit",
        },
        {
          name: "Amazon Web Services",
          iconifyClass: "logos-aws",
        },
      ],
    },
    {
      id: "3",
      name: "NFT Market Analysis and Trading 🎴",
      url: "https://github.com/yanismiraoui/",
      description: "Used a LightGBM model to correctly predict the true value of an NFT based on multiple different features. Developed an application able to automatically buy, sell and manage an NFT portfolio based on their current price and their trend. Built a complete web dashboard to track the transactions made and their trends.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Git",
          iconifyClass: "logos:git-icon",
        },
        {
          name: "Google Cloud Platform",
          iconifyClass: "logos-google-cloud",
        },
        {
          name: "NodeJS",
          iconifyClass: "logos:nodejs-icon",
        },
        {
          name: "GraphQL",
          iconifyClass: "logos-graphql",
        },
        {
          name: "Python Selenium",
          iconifyClass: "logos-selenium",
        },
      ],
    },
    {
      id: "4",
      name: "Analyzing sports commentary 🏃‍♂️",
      url: "https://github.com/yanismiraoui/Analyzing-sports-commentary-in-order-to-automatically-recognize-events-and-extract-insights",
      description: "Investigated how we can use multiple different NLP techniques in order to automatically recognize the main actions in sports events. The aim was to extract insights by analyzing live sports commentaries from different sources and by classifying these major actions. This project was done in collaboration with ETH Zürich and Egoli Media.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Git",
          iconifyClass: "logos:git-icon",
        },
        {
          name: "Google Colab",
          iconifyClass: "simple-icons:googlecolab",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
        {
          name: "Heroku",
          iconifyClass: "logos-heroku-icon",
        },
        {
          name: "Python Selenium",
          iconifyClass: "logos-selenium",
        },
        {
          name: "Chromium Browser",
          iconifyClass: "openmoji-chromium",
        },
      ],
    },
    {
      id: "5",
      name: "ASVZ Booker 🤖",
      url: "https://github.com/yanismiraoui/asvz-bot-public",
      description: "This bot automatically books a spot for a given sport and event on ASVZ. I built it mainly using Python, Selenium and it is hosted on a AWS virtual machine. I have also built a website in order for anyone to be able to launch the bot easily at anytime.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Amazon Web Services",
          iconifyClass: "logos-aws",
        },
        {
          name: "Heroku",
          iconifyClass: "logos-heroku-icon",
        },
        {
          name: "Python Selenium",
          iconifyClass: "logos-selenium",
        },
        {
          name: "Chromium Browser",
          iconifyClass: "openmoji-chromium",
        },
      ],
    },
    {
      id: "6",
      name: "Fantasy Baseball Projections ⚾️",
      url: "https://github.com/yanismiraoui/",
      description:
        "Scraped, cleaned, and engineered the data and features of multiple datasets (BBref, Rotowire, Action Network, Statcast, ...) in order to build a LightGBM model that predicts the performance of baseball players in the next game. The model was then used to build an optimized fantasy baseball team that would maximize the points scored in the next game.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
        {
          name: "Heroku",
          iconifyClass: "logos-heroku-icon",
        },
        {
          name: "Python Selenium",
          iconifyClass: "logos-selenium",
        },
        {
          name: "LightGBM",
          iconifyClass: "arcticons:boost",
        },
      ],
    },
    {
      id: "7",
      name: "Fantasy Football Projections ⚽",
      url: "https://github.com/yanismiraoui/",
      description:
        "Scraped, cleaned, and engineered the data and features of multiple datasets (FBref, Rotowire, OddsPortal, ...) in order to build a LightGBM model that predicts the performance of soccer players in the next game. The model was then used to build an optimized fantasy football team that would maximize the points scored in the next game.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
        {
          name: "Heroku",
          iconifyClass: "logos-heroku-icon",
        },
        {
          name: "Python Selenium",
          iconifyClass: "logos-selenium",
        },
        {
          name: "LightGBM",
          iconifyClass: "arcticons:boost",
        },
      ],
    },
    {
      id: "8",
      name: "Cryptocurrency Trading Analysis 💲",
      url: "https://github.com/yanismiraoui/",
      description:
        "Aggregated, cleaned, and engineered the data and features of multiple datasets that contains data for 4 cryptocurrencies (Bitcoin, Ethereum, Ripple, Bitcoin-Cash) over a 7-year period (2013-2019). Developed a Linear Regression and Facebook Prophet model to predict the prices of cryptocurrencies and inform trading decisions.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
        {
          name: "Facebook Prophet",
          iconifyClass: "simple-icons:meta",
        },
      ],
    },
    {
      id: "9",
      name: "Ligue 1 Betting Market Analysis 🏆",
      url: "https://github.com/yanismiraoui/Predicting_Ligue_1",
      description:
        "Scraped, aggregated, and feature-engineered data related to the past Ligue 1 seasons from 3+ websites. Used a Random Forest model to achieve ~70% accuracy in predicting the outcome of Ligue 1 games",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Git",
          iconifyClass: "logos:git-icon",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
        {
          name: "NodeJS",
          iconifyClass: "logos:nodejs-icon",
        },
        {
          name: "Python Selenium",
          iconifyClass: "logos-selenium",
        },
        {
          name: "Chromium Browser",
          iconifyClass: "openmoji-chromium",
        },
      ],
    },
    {
      id: "10",
      name: "Mathematical Foundations of Machine Learning 📚",
      url: "https://github.com/yanismiraoui/MFML-Projects",
      description: "Projects done during the course of Mathematical Foundations of Machine Learning at Imperial College London.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Git",
          iconifyClass: "logos:git-icon",
        },
        {
          name: "Google Colab",
          iconifyClass: "simple-icons:googlecolab",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      id: "11",
      name: "Methods for Data Science 📚",
      url: "https://github.com/yanismiraoui/Methods-for-Data-Science-Projects",
      description: "Projects done during the course of Methods for Data Science at Imperial College London.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Git",
          iconifyClass: "logos:git-icon",
        },
        {
          name: "Google Colab",
          iconifyClass: "simple-icons:googlecolab",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      id: "12",
      name: "Probabilistic Artificial Intelligence 📚",
      url: "https://github.com/yanismiraoui/Probabilistic-Artificial-Intelligence-Projects-ETHZ",
      description:
        "Projects done during the course of Probabilistic Artificial Intelligence at ETH Zürich.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Git",
          iconifyClass: "logos:git-icon",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      id: "13",
      name: "Advanced Machine Learning 📚",
      url: "https://github.com/yanismiraoui/Advanced-Machine-Learning-Projects-ETHZ",
      description:
        "Projects done during the course of Advanced Machine Learning at ETH Zürich.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Git",
          iconifyClass: "logos:git-icon",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
    {
      id: "14",
      name: "Introduction to Machine Learning 📚",
      url: "https://github.com/yanismiraoui/Intro-to-ML-Projects-ETHZ",
      description:
        "Projects done during the course of Introduction to Machine Learning at ETH Zürich.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Git",
          iconifyClass: "logos:git-icon",
        },
        {
          name: "Jupyter Notebook",
          iconifyClass: "logos-jupyter",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
